/* Spotify auth font */
/* @import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Rubik&family=M+PLUS+Rounded+1c:wght@700;800&family=Modak&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--bg-gradient-red: hsl(336, 96%, 20%);
	--bg-gradient-blue: hsl(203, 75%, 17%);
	--fg-gradient-red: hsl(336, 96%, 40%);
	--fg-gradient-blue: hsl(203, 75%, 37%);
}

body {
	background-color: black;
}
